



import { gql } from 'graphql-tag';
import { defineAsyncComponent, defineComponent } from '@nuxtjs/composition-api';

import { contentableBlockColorFragment } from '@/composables/block-color';

export default defineComponent({
  components: {
    // Add data to original page component
    Page: defineAsyncComponent(() => import('@/pages/index.vue')),
  },
  graphqlData: gql`
    fragment StartpageContentData on StartpageContent {
      id
      blocks {
        __typename @inject
      }
      ...ContentableBlockColor
    }

    ${contentableBlockColorFragment}
  `,
});
